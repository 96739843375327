export default {
	init() {
		// JavaScript to be fired on all pages

		/**
		 * A basic function to scroll to a specific element
		 * @param elm
		 * @param duration The duration for the scroll animation to execute
		 */



		/**
		 * Skip links
		 */
		// skip link scroll to section
		const skipToAnchor = (aid) => {
			var aTag = $(aid);
			var focus = true;
			$('html,body').animate({
				scrollTop: aTag.offset().top
			}, 'slow');
			var first_child = $(aTag.children()[0]);
			var tag = first_child.prop('tagName').toLowerCase();

			if (tag !== 'a' && tag !== 'button' && tag !== 'input' && tag !== 'textarea') {
				if (first_child.attr('tabIndex') !== undefined) {
					first_child.attr('tabIndex', -1).focus();
					first_child.removeAttr('tabIndex');
				} else {
					first_child.focus();
				}
			} else {
				first_child.focus();
			}
		}

		// create skip links
		const skipLinks = () => {
			$('section').each(function () {
				const id = $(this).attr('id');
				if (id !== undefined) {
					// Use the section id to create a label for the items in the skip link list
					var sectionNameArray = id.split('-');
					var sectionName = '';
					for (var i = 0; i < sectionNameArray.length; i++) {
						var str = sectionNameArray[i];
						str = str.toLowerCase().replace(/\b[a-z]/g, function (letter) {
							return letter.toUpperCase();
						});
						sectionName += str;
						if (i < sectionNameArray.length - 1) {
							sectionName += " ";
						}
					}
					var skiplink = "<li><a href='#" + id + "' class='text-link'>Skip to " + sectionName + "</a></li>";
					$('.skiplinks ul').append(skiplink);
				}
			});

			const skipLinkContainer = $('.skiplinks'),
				skipLink = $('.skiplinks a');

			skipLink.on('focus', function () {
				skipLinkContainer.addClass('show');
			});

			skipLink.on('blur', function () {
				skipLinkContainer.removeClass('show');
			});

			skipLink.on('click', function (e) {
				e.preventDefault();
				skipToAnchor($(this).attr('href'));
			});
		}

		skipLinks();


		var sectionWaypoints = $("section").each(function () {
			$(this).waypoint({
				handler: function (direction) {
					$(this.element).addClass("loaded");
				}, offset: "50%"
			});

			if ($(this).hasClass("scroll-group")) {
				console.log("adding scroll group waypoints");
				$(this).waypoint({
					handler: function (direction) {
						if (direction === "down") {
							$(this.element).addClass("fixed");
						} else {
							$(this.element).removeClass("fixed");
						}
					}, offset: function () {
						return 100 + $("header.header").outerHeight();
					}
				});
				$(this).waypoint({
					handler: function (direction) {
						if (direction === "down") {
							var offset = $(this.element)
								.find(".row:last-child")
								.offset().top - $(this.element).offset().top;
							$(this.element)
								.removeClass("fixed")
								.addClass("fixed-bottom");
							$(this.element)
								.find(".fixed-item")
								.css({ top: offset });
						} else {
							$(this.element)
								.addClass("fixed")
								.removeClass("fixed-bottom");
							$(this.element)
								.find(".fixed-item")
								.removeAttr("style");
						}
					}, offset: function () {
						return $(this.element).height() * -1 + $(this.element)
							.find(".row")
							.last()
							.outerHeight() + 100;
					}
				});
			}
		});

		var scrollGroupWaypoints = $("section.scroll-group .row-content").each(
			function () {
				$(this).waypoint({
					handler: function (direction) {
						$(this.element).addClass("loaded");
					},
					offset: "77%"
				});
			}
		);

		$("section.count")
			.find(".number")
			.each(function () {
				var options = {};
				var $num = $(this);
				var start = $(this).data("start");
				var end = $(this).data("end");
				options.prefix = $(this).data("prefix") ? $(this).data("prefix") : "";
				options.suffix = $(this).data("suffix") ? $(this).data("suffix") : "";
				options.separator = $(this).data("separator") ? $(this).data("separator") : ",";
				options.decimal = $(this).data("decimal") ? $(this).data("decimal") : ".";

				var countAnim = new CountUp($num.attr("id"), start, end, 0, 2, options);

				var countWp = $(this).waypoint({
					handler: function (direction) {
						if (!countAnim.error) {
							countAnim.start();
						} else {
							console.log(countAnim.error);
						}
					}, offset: "50%"
				});
			});

		var lastWidth = 1440;

		// Basic Navigation
		const navButton = document.getElementById('navToggle');
		const mainNav = document.getElementById('mainNav');
		console.log('navButton', navButton);
		if (navButton) {
			navButton.addEventListener('click', (e => {
				e.preventDefault();
				navButton.classList.toggle('opened');
				mainNav.classList.toggle('opened');
			}));
		}

	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
		//TODO: move explore stuff to homepage file

		// Explore section
		const exploreSection = document.querySelector('#explore');
		const scrollableAbsolute = document.querySelector('#explore .scrollable');
		const tabletBreakpoint = 1200;

		const isTablet = () => {
			return window.innerWidth < tabletBreakpoint;
		}

		const setFixedHeight = () => {
			if (!isTablet()) {
				exploreSection.style.minHeight = scrollableAbsolute.getBoundingClientRect().height + 'px';
			} else if (isTablet() && exploreSection.style.minHeight !== 'unset') {
				exploreSection.style.minHeight = 'unset';
			}
		}

		setFixedHeight();


		const exploreButtons = document.querySelectorAll('section#explore a.dot');
		if (exploreButtons.length) {
			exploreButtons.forEach(button => {
				button.addEventListener('click', e => {
					// e.preventDefault();
					exploreButtons.forEach(otherButton => {
						otherButton.classList.remove('active')
					});
					button.classList.add('active');
				})
			})
		}

		const getInvolvedCards = document.querySelectorAll('#involved .involved-card');


		const collapseAllCards = (cards) => {
			cards.forEach(card => {
				card.classList.add('collapsed');
				card.classList.remove('expanded');
				card.querySelector('.info').setAttribute('aria-hidden', true);
			})
		}

		const expandAllCards = (cards) => {
			cards.forEach(card => {
				card.classList.remove('collapsed');
				card.classList.add('expanded');
				card.querySelector('.info').setAttribute('aria-hidden', false);
			})
		}

		const responsiveInvolvedCardSetup = (cards) => {
			const parent = cards[0]?.parentNode;
			if (isTablet() && !parent.classList.contains('responsive-mode')) {
				parent.classList.add('responsive-mode');
				expandAllCards(cards);
			} else if (parent.classList.contains('responsive-mode') && !isTablet()) {
				parent.classList.remove('responsive-mode');
				collapseAllCards(cards);
			}
		}



		// Run once on init
		responsiveInvolvedCardSetup(getInvolvedCards);

		// Items to run again on resize to keep everything tidy
		window.addEventListener('resize', () => {
			responsiveInvolvedCardSetup(getInvolvedCards);
			setFixedHeight();
		})

		// Card controls
		getInvolvedCards.forEach(card => {
			const button = card.querySelector('button');
			button.addEventListener('click', e => {
				e.preventDefault();
				// set all cards to starting state
				if (card.classList.contains('expanded')) {
					getInvolvedCards.forEach(allCard => {
						allCard.classList.remove('collapsed');
						allCard.classList.remove('expanded');
						allCard.querySelector('.info').setAttribute('aria-hidden', true);
					})
				} else {
					// Logic to handle expanding of cards
					getInvolvedCards.forEach(allCard => {
						if (allCard !== card) {
							allCard.classList.add('collapsed');
							allCard.classList.remove('expanded');
							allCard.querySelector('.info').setAttribute('aria-hidden', true);
						} else {
							allCard.classList.add('expanded')
							allCard.classList.remove('collapsed')
							allCard.querySelector('.info').setAttribute('aria-hidden', false);
						}
					})
				}
			})
		})
	}
};
