import Swiper, { Navigation, Pagination } from 'swiper';

function hero() {

    const slidesNum = document.querySelectorAll("#hero .swiper-slide").length;
    const heroPagi = document.querySelector(".swiper-pagination");
    const heroPrev = document.querySelector(".swiper-prev");
    const heroNext = document.querySelector(".swiper-next");

    // check number of slides
    let touch, doLoop;

    if (slidesNum < 2) {
        // only 1 slide, remove controls
        heroPagi.remove();
        heroPrev.remove();
        heroNext.remove();
        touch = false;
        doLoop = false;
    } else {
        // add controls to swiper
        touch = true;
        doLoop = true;
    }

    const heroSwiper = new Swiper("#hero .swiper", {
        loop: doLoop,
        simulateTouch: touch,
        modules: [Navigation, Pagination],
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        a11y: {
            prevSlideMessage: "Previous slide",
            nextSlideMessage: "Next slide",
            firstSlideMessage: "This is the first slide",
            lastSlideMessage: "This is the last slide",
            paginationBulletMessage: "Go to slide {{index}}",
        },
        on: {
            init: function () {
                // accessibility
                // $(".swiper-slide .btn").attr("tabindex", "-1");
                // $(".swiper-slide-active .btn").attr("tabindex", "0");
            },
            transitionStart: function () {
                // accessibility
                // $(".swiper-slide .btn").attr("tabindex", "-1");
                // $(".swiper-slide-active .btn").attr("tabindex", "0");
            },
            transitionEnd: function () {
            },
        },
    });
}

export default hero;