import hero from "../util/swiper.js";

export default {
  init() {

    hero();
		
	},
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
